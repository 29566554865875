:root {
  --font-family: 'Montserrat', sans-serif;

  /* TODO: */
  /* --font-size-4xl: 44px;
    --font-size-3xl: 32px;
    --font-size-xxl: 28px;
    --font-size-xl: 22px;
    --font-size-l: 18px;
    --font-size-m: 16px;
    --font-size-s: 14px;
    --font-size-xs: 12px;

    --line-height-4xl: 56px;
    --line-height-3xl: 46px;
    --line-height-2xl: 40px;
    --line-height-xxl: 36px;
    --line-height-xl: 28px;
    --line-height-l: 24px;
    --line-height-m: 20px;
    --line-height-s: 16px; */
}
