@mixin screen-xxs {
  @media screen and (max-width: 440px) {
    @content;
  }
}

@mixin screen-xs {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin screen-s-and-lesser {
  @media screen and (max-width: 991.98px) {
    @content;
  }
}
