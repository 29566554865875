:root {
  --c-body: #0c0513;
  --c-background-dark: #3c135f;
  --c-background-light: #aa1e45;
  --c-background: linear-gradient(
    117.28deg,
    var(--c-background-dark) 22.77%,
    var(--c-background-light) 136.67%
  );
  --c-background-score: #893b6c;
  --c-background-secondary: rgba(0, 0, 0, 0.5);
  --c-background-primary: rgba(255, 255, 255, 0.1);
  --c-background-accent: linear-gradient(
    90deg,
    rgba(0, 15, 116, 0.5) 0%,
    rgba(232, 26, 30, 0.5) 100%
  );
  --c-background-hover: rgba(255, 255, 255, 0.25);
  --c-background-card: rgba(0, 0, 0, 0.2);
  --c-background-highlighted: rgba(86, 22, 89, 0.8);

  --c-button-primary: #f29d38;
  --c-button-primary-active: #b36100;
  --c-button-primary-hover: #ffb358;
  --c-button-primary-disabled: #683800;

  --c-text-primary: #ffffff;
}
