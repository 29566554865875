@import '../../styles/mixins/breakpoints.scss';

.button {
  height: 48px;
  border-radius: 78px;
  padding: 0;
  margin: 0;
  border: 1px solid var(--c-background-hover);
  color: var(--c-text-primary);
  background: none;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  padding: 0 20px;
  transition: var(--transition);
  display: inline-flex;
  align-items: center;
  line-height: 120%;
  outline: none !important;
  min-width: 0;

  &:not([disabled]) {
    cursor: pointer;
  }

  &:hover:not([disabled]) {
    border: 1px solid rgba(255, 255, 255, 0.4);
  }

  &.block {
    display: flex;
    width: 100%;
  }

  &.link {
    background: none;
    border: inherit;
    height: auto;
    font-size: inherit;
    border-radius: 0;
    padding: 0;

    &:hover:not([disabled]) {
      border: inherit;
    }
  }

  &.primary {
    background: var(--c-button-primary);
    border-color: var(--c-button-primary);
    font-weight: bold;

    &:hover:not([disabled]) {
      background: var(--c-button-primary-hover);
      border-color: var(--c-button-primary-hover);

      &:focus {
        background: var(--c-button-primary-active);
        border-color: var(--c-button-primary-active);
      }
    }

    &[disabled] {
      background: var(--c-button-primary-disabled);
      border-color: var(--c-button-primary-disabled);
      color: rgba(255, 255, 255, 0.5);
      cursor: no-drop;
    }
  }

  &.secondary {
    font-weight: bold;
    color: var(--c-text-primary);
    background: var(--c-background-hover);
    border-color: transparent;
    font-weight: 500;
  }

  &.answer {
    background: var(--c-background-primary);
    border: inherit;
    font-weight: 500;
    text-align: left;

    &[disabled] {
      background: none;
      box-shadow: inset 0 0 1px 1px var(--c-background-primary);
    }

    &:hover:not([disabled]) {
      border: inherit;
    }

    &:hover:not([disabled]):not(:focus) {
      background: var(--c-background-hover);
    }

    &:focus,
    &.active {
      background: var(--c-button-primary);
    }

    &.active[disabled] {
      background: var(--c-background-primary);
      color: var(--c-text-primary);
    }
  }

  &.empty {
    height: auto;
    background: none;
    border: 0;
    padding: 0;

    &:hover {
      border: 0;
    }
  }

  &.alignStart {
  }

  .icon.start:not(.withoutChildren) {
    margin-left: -5px;
    margin-right: 8px;
  }

  .icon.end:not(.withoutChildren) {
    margin-left: auto;
    margin-right: -5px;
  }

  .text {
    &.textEllipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.alignCenter {
      justify-content: center;
      flex: 1;
    }
    &.withIconAtEnd {
      margin-right: 5px;
    }
  }
}

@include screen-xs {
  .button {
    height: 37px;
    font-size: 12px;

    .icon.start:not(.withoutChildren) {
      margin-left: -7px;
      margin-right: 7px;
    }

    .icon.end:not(.withoutChildren) {
      margin-right: -7px;
    }
  }
}
