@import '../../styles/mixins/breakpoints.scss';

.container {
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1600px;
  margin: 30px auto 0;

  &::before {
    content: '';
    position: absolute;
    border-top: 1px solid var(--c-background-primary);
    top: 0;
    left: 20px;
    right: 20px;
  }
}

.chat {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: auto;

  .chatText {
    color: rgba(255, 255, 255, 0.5);
    margin-left: 15px;
    margin-right: 15px;
    font-size: 11px;
    letter-spacing: -0.01em;
  }
}

.buttonContent {
  opacity: 0.4;

  .buttonText {
    margin-left: 7px;
    font-weight: 600;
  }
}

.socials {
  .socialsList {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 11px;
    height: 18px;
    li {
      height: 100%;
    }

    li + li {
      margin-left: 20px;
    }
    .socialsListText {
      margin-left: 10px;
    }
    a {
      color: var(--c-text-primary);
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.copy {
  opacity: 0.4;
  font-size: 13px;
  margin-left: auto;

  .logo {
    margin-left: 20px;
    width: 92px;
  }
}

.socials,
.copy {
  padding-top: 10px;
  padding-bottom: 10px;
}

@include screen-xs {
  .container,
  .chat {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .copy {
    margin-left: 0;
  }

  .chat {
    margin-right: 0;
  }

  .chatText {
    margin: 20px 0;
  }

  .buttonText {
    font-size: 12px;
  }
}
