@import './variables/color.css';
@import './variables/control.css';
@import './variables/font.css';

body {
  background: var(--c-body);
}

body,
td,
th {
  color: var(--c-text-primary);
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
}

a {
  color: var(--c-text-primary);
  text-decoration: none !important;
  transition: var(--transition);
}
