@import '../../styles/mixins/breakpoints.scss';

.layout {
  background: var(--c-background);
  position: relative;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100svh;

  .bg {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
  }

  .headerBg {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      background-image: url('./bg.png');
      width: 100%;
      height: 108px;
      background-size: contain;
      background-repeat: repeat-x;
      background-position: 50% 100%;
      opacity: 0.8;
    }
  }

  &.quiz {
    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      /* background-image: url('./bg-overlay.png'); */
      background-size: cover;
      background-position: 50%;
    }
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  flex: 1;

  &:not(.alignStart) {
    justify-content: center;
  }
}

.footerContainer {
  width: 100%;
  z-index: 2;
}

@include screen-s-and-lesser {
  .layout {
    .headerBg::after {
      height: 25px;
    }
  }
}
